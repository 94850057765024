<template>
  <div class="block-share">
    <h4>Share it:</h4>
    <a target="_blank" class="social-link" href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://dev.bahamacoders.com/travelunionwp/gold-travel-cashback-trip-14/" aria-hidden="true"><i class="fab fa-linkedin-in"></i></a>
    <a target="_blank" class="social-link" href="https://www.facebook.com/sharer/sharer.php?u=https://dev.bahamacoders.com/travelunionwp/gold-travel-cashback-trip-14/" aria-hidden="true"><i class="fab fa-facebook-f"></i></a>
    <a target="_blank" class="social-link" href="https://twitter.com/intent/tweet?source=https://dev.bahamacoders.com/travelunionwp/gold-travel-cashback-trip-14/" aria-hidden="true"><i class="fab fa-twitter"></i></a>
  </div>
</template>

<script>

</script>

<style>

</style>