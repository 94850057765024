<template>
  <div>
    <h1>Данное приложение было создано в результате обучения на канале Ulbi TV</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
