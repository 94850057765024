<template>

  <section v-if="$route.path !== '/'" class="page-title page-title-blog">
    <div class="container">
      <div class="page-title__cards">
        <img class="card-img blue-card wow topCard1" data-wow-duration="0.8s" src="http://dev.bahamacoders.com/travelunionwp/wp-content/themes/tuwp/img/card-4.png" alt="/" style="visibility: visible; animation-duration: 0.8s; animation-name: m;">
        <img class="card-img black-card wow topCard2" data-wow-duration="0.8s" src="http://dev.bahamacoders.com/travelunionwp/wp-content/themes/tuwp/img/card-3.png" alt="/" style="visibility: visible; animation-duration: 0.8s; animation-name: n;">
      </div>
      <h1>Travel Union Blog</h1>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
