import axios from "axios";


export const authModule = {
    state: () => ({
        authData: [],
        isAuthDataLoading: false,
    }),
    mutations: {
        setAuthData(state, authData) {
            state.authData = authData;
        },
        setAuthDataLoading(state, bool) {
            state.isAuthDataLoading = bool
        }
    },
    actions: {
        async fetchAuthData({state, commit}) {
            try {
                commit('setAuthDataLoading', true);
                const response = await axios.post('https://dev.bahamacoders.com/travelunionwp/wp-json/jwt-auth/v1/token', {
                    params: {
                        password: 'travel_pass',
                        username: 'travel_admin'
                    }
                });
                commit('setAuthData', response.data);
            } catch (e) {
                console.log(e)
            } finally {
                commit('setAuthDataLoading', false);

                console.log(response.data)
            }
        },

    },
    namespaced: true
}
